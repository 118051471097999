import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useQuery } from "react-query";
import { addresses } from "@project/contracts";

import { Hero, AlertContext, Alert, QRScan } from "./components";
import { Dashboard, PublicList, KeyView, NewKey } from "./pages";
import useWeb3Modal from "./hooks/useWeb3Modal";

import "./app.scss";
import { fetchAssetsOpensea } from "./util/api";
import { readOnChainData, readTokenDataFromContract } from "./util/contract";

const MINTER_ROLE =
  "0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6";

const targetNetwork = {
  chainId: 89,
  altChainId: 137,
  name: "Matic Mainnet",
  rpcUrl: "https://rpc-mainnet.matic.network",
  blockExplorerUrl: "https://explorer.matic.network/"
};

// TEST NETWORK
// const targetNetwork = {
//   chainId: 4,
//   name: "Rinkeby Test Network",
//   rpcUrl: "https://rinkeby.infura.io/v3/undefined",
//   blockExplorerUrl: "https://rinkeby.etherscan.io"
// };

async function processOpenSeaAssets(assets) {
  const tokenData = [];
  for (let i = 0; i < assets.length; i++) {
    const metadata = await fetch(assets[i].token_metadata, {
      method: "GET"
    }).then(data => data.json());
    tokenData.push({
      keyImage: metadata.image,
      signature: metadata.attributes[0].value,
      keyNum: Number(metadata.attributes[1].value),
      recipient: metadata.attributes[2].value,
      dedication: metadata.attributes[3].value,
      link: assets[i].permalink
    });
  }
  return tokenData;
}

function App() {
  const { isLoading, error, data } = useQuery("keyQuery", () =>
    fetchAssetsOpensea(addresses.keyMinter)
  );
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  const [awaitingTxn, setAwaitingTxn] = useState(false);
  const [numLeft, setNumLeft] = useState(0);
  const [counter, setCounter] = useState(null);
  const [keyMinter, setKeyMinter] = useState();
  const [keyData, setKeyData] = useState();
  const [userAddress, setUserAddress] = useState();
  const [alert, setAlert] = useState(null);

  const showAlert = alert => {
    setAlert(alert);
    setTimeout(() => {
      setAlert(null);
      if (alert.onEnd) {
        alert.onEnd();
      }
    }, alert.duration ?? 2000);
  };

  // Fetch Key Data
  useEffect(() => {
    let subscribed = true;

    if (
      !isLoading &&
      data &&
      data.assets &&
      !keyData &&
      counter &&
      counter > 0
    ) {
      if (!error && data.assets.length > 0) {
        const openSeaAssetLength = data.assets.length;
        console.log("Fetching from OpenSea...");
        processOpenSeaAssets(data.assets)
          .then(openSeaTokenData => {
            // if opensea has not caught up, fetch data from the contract directly
            // merge key data with data from contract
            if (counter !== openSeaAssetLength && keyMinter) {
              console.log("OpenSea not up to date. Fetching from Contract...");
              readTokenDataFromContract(
                openSeaAssetLength + 1,
                counter,
                keyMinter
              )
                .then(contractTokenData => {
                  if (subscribed) {
                    setKeyData([...contractTokenData, ...openSeaTokenData]);
                  }
                })
                .catch(err => {
                  console.error("Couldn't fetch contract data", err);
                });
            } else if (subscribed) {
              setKeyData(openSeaTokenData);
            }
          })
          .catch(err => {
            console.log("Error fetching from OpenSea", err);
            if (keyMinter) {
              readTokenDataFromContract(1, counter, keyMinter).then(
                contractTokenData => {
                  if (subscribed) {
                    setKeyData(contractTokenData);
                  }
                }
              );
            }
          });
      } else if (keyMinter) {
        readTokenDataFromContract(1, counter, keyMinter)
          .then(contractTokenData => {
            if (subscribed) {
              console.log("Contract data", contractTokenData);
              setKeyData(contractTokenData);
            }
          })
          .catch(err => {
            console.log("Couldn't read tokens from contract", err);
          });
      }
    }

    return () => {
      subscribed = false;
    };
  }, [isLoading, error, data, counter, keyMinter, keyData]);

  useEffect(() => {
    let subscribed = true;
    readOnChainData(provider)
      .then(contractData => {
        if (subscribed) {
          console.log({
            contractData
          });
          setCounter(contractData.counter);
          setNumLeft(contractData.numLeft);
          setKeyMinter(contractData.keyMinter);
        }
      })
      .catch(e => {
        if (subscribed) {
          setCounter(0);
          console.log(`Failed to read data: ${e}`);
        }
      });

    return () => {
      subscribed = false;
    };
  }, [provider]);

  // Redirect Mayor on Wallet Connect
  useEffect(() => {
    if (provider && keyMinter) {
      // Redirect on Wallet Connect
      provider
        .getSigner()
        .getAddress()
        .then(address => {
          setUserAddress(address);
          keyMinter.functions.hasRole(MINTER_ROLE, address).then(result => {
            if (
              window.location.pathname === "/" ||
              window.location.pathname === "/keys"
            ) {
              if (result[0]) {
                window.location.href = "/dashboard";
              } else {
                window.location.href = "/public/dashboard";
              }
            }
          });
        });
    }
  }, [provider, keyMinter]);

  useEffect(() => {
    if (
      userAddress &&
      keyMinter &&
      window.location.pathname.split("/")[1] !== "key" &&
      keyData
    ) {
      // Alert on Key receive
      const listener = (from, to, tokenId) => {
        let exists = keyData.reduce(
          (acc, { keyNum }) => +keyNum === tokenId.toNumber() || acc,
          false
        );
        if (to === userAddress && from !== userAddress && !exists) {
          showAlert({
            type: "newKey",
            desc: "You received a new key",
            duration: 5000,
            onEnd: () => {
              window.location.href = `/key/${tokenId.toNumber()}`;
            }
          });
        }
      };
      keyMinter.on("Transfer", listener);
      return () => {
        keyMinter.off("Transfer", listener);
      };
    }
  }, [userAddress, keyMinter, keyData]);

  useEffect(() => {
    let subscribed = true;
    if (provider) {
      provider.getNetwork().then(network => {
        if (
          network &&
          network.chainId !== targetNetwork.chainId &&
          network.chainId !== targetNetwork.altChainId &&
          !awaitingTxn
        ) {
          setAwaitingTxn(true);
          setAlert({
            type: "network",
            desc: `Please switch to the ${targetNetwork.name} network`
          });
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x" + targetNetwork.chainId,
                  chainName: targetNetwork.name,
                  rpcUrls: [targetNetwork.rpcUrl],
                  blockExplorerUrls: [targetNetwork.blockExplorerUrl]
                }
              ]
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              if (subscribed) {
                setAwaitingTxn(false);
              }
            });
        }
      });
    }
    return () => {
      subscribed = false;
    };
  }, [awaitingTxn, setAwaitingTxn, provider]);

  return (
    <div className="app">
      <AlertContext.Provider value={showAlert}>
        <Router>
          <Switch>
            <Route path="/" exact>
              <Hero
                provider={provider}
                loadWeb3Modal={loadWeb3Modal}
                logoutOfWeb3Modal={logoutOfWeb3Modal}
              />
            </Route>
            <Route path="/dashboard" exact>
              <Dashboard numLeft={numLeft} keyData={keyData} isMayor={true} />
            </Route>
            <Route path="/public/dashboard" exact>
              <Dashboard
                numLeft={numLeft}
                keyData={keyData}
                isMayor={false}
                address={userAddress}
              />
            </Route>
            <Route path="/newKey" exact>
              <NewKey keyMinter={keyMinter} keyNum={counter + 1} />
            </Route>
            <Route path="/scan" exact>
              <QRScan address={userAddress} />
            </Route>
            <Route path="/keys">
              <PublicList keyData={keyData} />
            </Route>
            <Route path="/key/:id" exact>
              <KeyView keyData={keyData} />
            </Route>
          </Switch>
        </Router>
        <Alert {...alert} />
      </AlertContext.Provider>
    </div>
  );
}

// Reload on Network Switch
window.ethereum &&
  window.ethereum.on("chainChanged", () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  });

export default App;
