import React from "react";
import "./Container.scss";

export default function Container({ title, children }) {
  return (
    <div className="content-container">
      <h4 className="content-header">{title}</h4>
      {children}
    </div>
  );
}
