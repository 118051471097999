import React from "react";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import "./BackButton.scss";

export default function BackButton({ action }) {
  return (
    <div
      className="back-button"
      onClick={action ? action : () => window.history.back()}
    >
      <Arrow />
    </div>
  );
}
