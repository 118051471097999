import React from "react";
import { useParams } from "react-router-dom";
import { Key, Container, BackButton, Loading } from "../components";
import { ReactComponent as OpenSeaLogo } from "../assets/opensea-logo.svg";
import "./KeyView.scss";

export default function KeyView({ keyData }) {
  const { id } = useParams();
  const key =
    (keyData &&
      keyData[keyData.map(item => item.keyNum).indexOf(Number(id))]) ||
    JSON.parse(window.localStorage.getItem(`key_${id}`));

  const uri =
    key &&
    key.keyImage &&
    `https://ipfs.io/${key.keyImage.split("ipfs://")[1]}`;

  const signatureUri =
    key &&
    key.signature &&
    key.signature.indexOf("ipfs://") >= 0 &&
    `https://ipfs.io/${key.signature.split("ipfs://")[1]}`;

  if (typeof keyData === "undefined") {
    return <Loading />;
  }

  return key ? (
    <>
      <BackButton />
      <Key label={`Key ${Number(id)}`} altImg={uri} />
      <Container>
        <div className="key-info-container">
          <h2>Key Info</h2>
          <h4>Recipient(s)</h4>
          <p>{key.recipient}</p>
          <h4>Dedication</h4>
          <p>{key.dedication}</p>
          <h4>Signature</h4>
          <img
            alt=""
            src={signatureUri || key.signatureImage}
            className="signature"
          />
          {key.link ? (
            <a href={key.link}>
              <OpenSeaLogo />
              View on OpenSea
            </a>
          ) : null}
        </div>
      </Container>
    </>
  ) : (
    <p>Key not found</p>
  );
}
