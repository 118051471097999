import React from "react";
import { useHistory } from "react-router-dom";
import { Container, TxnList } from "../components";
import banner from "../assets/banner.png";
import key from "../assets/key.svg";
import "./PublicList.scss";

export default function PublicList({ keyData }) {
  const history = useHistory();

  return (
    <div className="public-list-container">
      <div
        className="public-header"
        onClick={() => {
          history.push("/");
        }}
      >
        <img alt="logo" src={banner} />
        <div className="header-text">
          <h1>Miami</h1>
          <h3>NFT Keys to the City</h3>
        </div>
      </div>
      <Container>
        <img alt="key" src={key} className="key" />
        <TxnList keyData={keyData} />
      </Container>
    </div>
  );
}
