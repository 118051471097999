import React from "react";
import profileImg from "../assets/banner.png";
import "./Header.scss";

export default function Header({ username, city, numLeft = null }) {
  return (
    <div id="header">
      <div className="user-container">
        <img alt="profile" src={profileImg} />
        <div className="user-info">
          <h4>{username}</h4>
          <p>{city}</p>
        </div>
      </div>
      {numLeft ? <h3>{numLeft} LEFT</h3> : null}
    </div>
  );
}
