import React, { createContext } from "react";
import Confetti from "react-confetti";
import { ReactComponent as ErrorIcon } from "../assets/error.svg";
import { ReactComponent as WarningIcon } from "../assets/warning.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
import { ReactComponent as KeyImg } from "../assets/key.svg";
import "./Alert.scss";

export const AlertContext = createContext();

export function Alert({ type, desc }) {
  const { innerWidth, innerHeight } = window;

  switch (type) {
    case "error":
      return (
        <div className="alert">
          <ErrorIcon className="error" />
          <h1>Error</h1>
          <h2>{desc}</h2>
        </div>
      );
    case "network":
      return (
        <div className="alert">
          <WarningIcon className="error" />
          <h1>Wrong Network</h1>
          <h2>{desc}</h2>
        </div>
      );
    case "success":
      return (
        <div className="alert">
          <SuccessIcon className="success" />
          <h1>Success</h1>
          <h2>{desc}</h2>
        </div>
      );
    case "newKey":
      return (
        <div className="alert">
          <Confetti
            width={innerWidth}
            height={innerHeight}
            colors={["#F6D340"]}
            confettiSource={{
              x: innerWidth / 2,
              y: innerHeight / 2,
              w: 0,
              h: 0
            }}
            initialVelocityY={{ min: -30, max: 30 }}
            initialVelocityX={{ min: -30, max: 30 }}
            tweenDuration={1}
            numberOfPieces={1000}
            recycle={false}
          />
          <KeyImg className="key" />
          <h1>Congratulations!</h1>
          <h2>{desc}</h2>
        </div>
      );
    default:
      return null;
  }
}
