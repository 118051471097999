import React from "react";
import bannerImg from "../assets/banner.png";
import { WalletButton } from ".";
import "./Hero.scss";

export default function Hero({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  return (
    <div className="hero-container">
      <div className="logo-container">
        <img className="logo" alt="banner" src={bannerImg} />
      </div>
      <div className="hero-text">
        <h1>Miami</h1>
        <h2>NFT Key to the City</h2>
        <p>Bringing the future of Blockchain to cities across America</p>
        <a href="/keys">View Keys</a>
        <WalletButton
          provider={provider}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
        />
      </div>
    </div>
  );
}
