import React, { useRef, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import SignaturePad from "signature_pad";
import {
  BackButton,
  Key,
  Container,
  WalletScan,
  Loading,
  AlertContext
} from "../components";
import { ReactComponent as Undo } from "../assets/undo.svg";
import "./NewKey.scss";
import { pinContent } from "../util/api";
import { env } from "../config";

export default function NewKey({ keyMinter, keyNum }) {
  const history = useHistory();
  const [recipient, setRecipient] = useState("");
  const [dedication, setDedication] = useState("");
  const [signature, setSignature] = useState(null);
  const [sigPad, setSigPad] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [keyId, setKeyId] = useState();
  // mintStatus: null | "processing" | "minting" | "minted"
  const [mintStatus, setMintStatus] = useState(null);
  const canvas = useRef();
  const showAlert = useContext(AlertContext);

  const scrollTo = e => {
    const top = e.nativeEvent.target.offsetTop + window.innerHeight;
    window.scrollTo({
      top,
      left: 0,
      behaviour: "smooth"
    });
  };

  const resetSignature = () => {
    sigPad.clear();
    setSignature(null);
  };

  const saveSignature = function() {
    if (this.isEmpty()) {
      setSignature(null);
    } else {
      setSignature(this.toDataURL());
    }
  };

  const mintKey = async to => {
    setMintStatus("processing");
    try {
      const keyCount = await keyMinter.counter();
      // console.log({ keyCount });
      const tokenId = keyCount.add(1).toString();
      const result = await pinContent({
        signatureImage: signature,
        tokenId,
        recipientName: recipient,
        dedication
      });
      const tokenUri = result.tokenUri;
      await keyMinter.mint(to, tokenId, tokenUri, {gasPrice: env.gasPrice});
      window.localStorage.setItem(
        `key_${tokenId}`,
        JSON.stringify({ signatureImage: signature, recipient, dedication })
      );
      // console.log(
      //   `key_${tokenId}`,
      //   JSON.stringify({ signatureImage: signature, recipient, dedication })
      // );
      setKeyId(tokenId);
      setMintStatus("minting");
    } catch (error) {
      console.error(error);
      if (error.code === 4001) {
        showAlert({
          type: "error",
          desc: "Transaction Declined",
          duration: 2000
        });
      } else {
        showAlert({
          type: "error",
          desc: error.message,
          duration: 2000
        });
      }
      setMintStatus(null);
      resetSigPad();
    }
  };

  const resetSigPad = () => {
    const signaturePad = new SignaturePad(canvas.current, {
      onEnd: saveSignature
    });
    setSigPad(signaturePad);
    setSignature(null);
  };

  useEffect(() => {
    if (canvas.current && !sigPad) {
      const signaturePad = new SignaturePad(canvas.current, {
        onEnd: saveSignature
      });
      setSigPad(signaturePad);
    }
  }, [sigPad]);

  // Wait for transaction to process
  useEffect(() => {
    if (mintStatus === "minting" && keyId && keyMinter) {
      const listener = (from, to, tokenId) => {
        if (tokenId.toNumber() === +keyId) {
          setMintStatus("minted");
          showAlert({
            type: "success",
            desc: "Your Key has Been Sent!",
            duration: 2000,
            onEnd: () => {
              history.push("/dashboard");
              history.push(`/key/${keyNum}`);
            }
          });
        }
      };
      keyMinter.on("Transfer", listener);
      return () => {
        keyMinter.off("Transfer", listener);
      };
    }
  }, [mintStatus, history, keyId, keyMinter, keyNum, showAlert]);

  useEffect(() => {
    // Resize canvas to fix DPI issues
    if (canvas.current && sigPad) {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.current.width = canvas.current.offsetWidth * ratio;
      canvas.current.height = canvas.current.offsetHeight * ratio;
      canvas.current.getContext("2d").scale(ratio, ratio);
      sigPad.clear();
    }
  }, [canvas, sigPad]);

  if (mintStatus === "processing") {
    return <Loading text="Your Key is Being Processed..." />;
  } else if (mintStatus === "minting") {
    return <Loading text="Your Key is Being Minted..." />;
  }

  return (
    <>
      <BackButton />
      <Key label={`Key ${keyNum}`} />{" "}
      <Container>
        <div className="key-input-container">
          <h2>Set Info</h2>
          <h4>Recipient(s)</h4>
          <input
            onFocus={scrollTo}
            name=""
            type="text"
            value={recipient}
            onChange={e => {
              setRecipient(e.nativeEvent.target.value);
            }}
            placeholder="Eg. John Smith"
          />
          <h4>Dedication</h4>
          <textarea
            onFocus={scrollTo}
            name=""
            type="text"
            value={dedication}
            onChange={e => {
              setDedication(e.nativeEvent.target.value);
            }}
            placeholder="Eg. Aided in the development of the Miami Tech Community"
          />
          <h4>Signature</h4>
          <div className="signature">
            <canvas ref={canvas}></canvas>
            <Undo
              className={signature ? "" : "hidden"}
              onClick={resetSignature}
            />
          </div>
          <div className="button-container">
            <button
              disabled={!recipient || !dedication || !signature}
              onClick={() => {
                setScanning(true);
              }}
            >
              Scan Recipient
            </button>
          </div>
        </div>
      </Container>
      {scanning ? (
        <WalletScan
          setAddress={address => {
            /* console.log({ address }); */
            setScanning(false);
            // have to pass in address because useState is variable does not update during the same render
            mintKey(address);
          }}
          onCancel={() => {
            setScanning(false);
          }}
        />
      ) : null}
    </>
  );
}
