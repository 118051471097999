import { env } from "../config";

export function apiRequest({
  baseUrl = env.baseURL,
  path,
  method = "GET",
  data
}) {
  return fetch(`${baseUrl}/${path}`, {
    method,
    headers: {
      "Content-Type": "application/json"
    },
    body: data ? JSON.stringify(data) : undefined
  }).then(res => res.json());
}

export function pinContent({
  signatureImage,
  tokenId,
  recipientName,
  dedication
}) {
  return apiRequest({
    path: "v1/pin",
    method: "POST",
    data: { signatureImage, tokenId, recipientName, dedication }
  });
}

export function fetchAssetsOpensea(contract) {
  return apiRequest({
    baseUrl: env.openseaBaseUrl,
    path: `v1/assets?asset_contract_address=${contract}&limit=50`,
    method: "GET"
  });
}

// export function demoQuery() {
//   return fetch('https://api.github.com/repos/tannerlinsley/react-query').then((res) => res.json())
// }

export function demoQuery() {
  return apiRequest({
    baseUrl: "https://api.github.com",
    path: "repos/tannerlinsley/react-query",
    method: "GET"
  });
}

export function fetchAssetOpensea(contract, tokenId) {
  return apiRequest({
    baseUrl: env.openseaBaseUrl,
    path: `v1/asset/${contract}/${tokenId}`,
    method: "GET"
  });
}

export function fetchMetadataPinata(tokenCid) {
  return apiRequest({
    baseUrl: env.pinataBaseUrl,
    path: `/ipfs/${tokenCid}`,
    method: "GET"
  });
}
