import React from "react";
import "./TxnList.scss";

export default function TxnList({ keyData }) {
  if (typeof keyData === "undefined") {
    return <div className="list-loader" />;
  }

  return (
    <>
      {keyData?.map(({ recipient, dedication, keyNum }) => (
        <div key={keyNum} className="txn-item">
          <div className="txn-info">
            <h4>key {keyNum}</h4>
            <h3>{recipient}</h3>
            <p>{dedication}</p>
          </div>
          <a href={`/key/${keyNum}`}>VIEW</a>
        </div>
      )) || <p className="no-items">No previous transactions</p>}
    </>
  );
}
