import React from "react";
import "./Loading.scss";

export default function Loading({ text }) {
  return (
    <div className="loading-container">
      <div className="loader" />
      <h2>{text}</h2>
    </div>
  );
}
