import React from "react";
import { useHistory } from "react-router-dom";
import { Header, Container, TxnList } from "../components";
import "./Dashboard.scss";

export default function Dashboard({
  numLeft,
  keyData,
  isMayor = false,
  address
}) {
  const history = useHistory();
  const userAddress = address
    ? `${address.slice(0, 6)}...${address.slice(37, 41)}`
    : "";

  if (isMayor) {
    return (
      <>
        <Header username="Mayor Suarez" city="Miami" numLeft={numLeft} />
        <Container title="Previously Sent">
          <TxnList keyData={keyData} />
        </Container>
        <button
          className="new-key-button"
          onClick={() => {
            history.push("/newKey");
          }}
        >
          Send New Key
        </button>
      </>
    );
  }

  return (
    <>
      <Header username={userAddress} city="Miami" />
      <Container title="Keys">
        <TxnList keyData={keyData} />
      </Container>
      <button
        className="scan new-key-button"
        onClick={() => {
          history.push("/scan");
        }}
      >
        Recieve New Key
      </button>
    </>
  );
}
