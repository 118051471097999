import { env } from "../config";
import { getDefaultProvider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { abis } from "@project/contracts";
import { fetchMetadataPinata } from "./api";

export async function readOnChainData(provider) {
  let signer = null;
  try {
    signer = await provider.getSigner();
  } catch (err) {
    console.log("Couldn't get Signer from Provider");
  }
  const fallbackProvider = getDefaultProvider(env.infuraUrl);
  const keyMinter = new Contract(
    env.contractAddress,
    abis.keyMinter,
    signer || provider || fallbackProvider
  );
  const maxSupply = await keyMinter.functions.maxSupply();
  const counter = await keyMinter.counter();
  const numLeft = (await maxSupply) - counter;
  return {
    numLeft,
    keyMinter,
    counter: counter.toNumber()
  };
}

export async function readTokenDataFromContract(minIndex, maxIndex, keyMinter) {
  const tokenData = [];

  for (let index = minIndex; index <= maxIndex; index++) {
    try {
      const [tokenUri] = await keyMinter.functions.tokenURI(index);
      const splitUrl = tokenUri.split("/");
      const tokenCid = splitUrl[splitUrl.length - 1];
      const ipfsData = await fetchMetadataPinata(tokenCid);
      tokenData.push({
        keyImage: ipfsData.image,
        signature: ipfsData.attributes[0].value,
        keyNum: Number(ipfsData.attributes[1].value),
        recipient: ipfsData.attributes[2].value,
        dedication: ipfsData.attributes[3].value
      });
    } catch (e) {
      console.error(`Failed to read data: ${e}`);
    }
  }
  return tokenData;
}
