import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import keyMinterAbi from "./abis/KeyMinter.json";
import keyMinterFactoryAbi from "./abis/KeyMinterFactory.json";

const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  keyMinter: keyMinterAbi,
  keyMinterFactory: keyMinterFactoryAbi,
};

export default abis;
