import React from "react";
// import key from "../assets/key.svg";
import keyMov from "../assets/key.mov";
import "./Key.scss";

export default function Key({
  label
  // altImg
}) {
  return (
    <div className="key-container">
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        src={keyMov}
      ></video>
      {/* <img alt="key" src={altImg ?? key} /> */}

      <h2>{label}</h2>
      <p>
        Designed by <a href="https://linktr.ee/seanmick">Sean Mick</a>
      </p>
    </div>
  );
}
