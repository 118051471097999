import React, { useRef, useEffect, useState } from "react";
import QrScanner from "qr-scanner";
import { BackButton } from ".";
import { ReactComponent as QrPrompt } from "../assets/qr-prompt.svg";
import { ReactComponent as QrPromptConfirmed } from "../assets/qr-prompt-confirmed.svg";
import { ReactComponent as NoVideo } from "../assets/no-camera.svg";
import { ReactComponent as Check } from "../assets/check.svg";
import "./WalletScan.scss";

QrScanner.WORKER_PATH = "qr-scanner-worker.min.js";

const ethReg = /^0x[a-fA-F0-9]{40}$/;

export default function WalletScan({ setAddress, onCancel }) {
  const videoElem = useRef();
  const [qrScanner, setQrScanner] = useState(null);
  const [input, setInput] = useState("");
  const [permissionsError, setPermissionsError] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  // const endScan = () => {
  //   qrScanner.stop();
  // };

  const isValid = () => input?.match(ethReg)?.length > 0;

  const promptPermission = () => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({
          video: true
        })
        .then(() => {
          console.log("Approved Permission");
          setPermissionsError(null);
        })
        .catch(err => {
          setPermissionsError(err);
        });
    }
  };

  useEffect(() => {
    if (videoElem.current) {
      setQrScanner(
        new QrScanner(videoElem.current, result => {
          const delim = result.indexOf(":");
          const protocol = result.slice(0, delim);
          const address = result.slice(delim + 1);

          if (protocol === "ethereum") {
            setInput(address);
            setConfirmed(true);
            setTimeout(() => {
              setConfirmed(false);
            }, 3000);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (qrScanner) {
      qrScanner.start().catch(err => {
        setPermissionsError(err);
      });
    }
  }, [qrScanner]);

  return (
    <div className="wallet-scan">
      <BackButton action={onCancel} />
      <video ref={videoElem}></video>
      <div className="qr-overlay">
        {permissionsError ? (
          <>
            <NoVideo className="no-video" />
            <button className="permissions-prompt" onClick={promptPermission}>
              Approve Camera Permissions
            </button>
          </>
        ) : (
          <>
            {confirmed ? (
              <QrPromptConfirmed className="qr-guide" />
            ) : (
              <QrPrompt className="qr-guide" />
            )}
            <p>Scan Recipient QR Code</p>
          </>
        )}
        <p>Or</p>
        <div className="manual-input">
          <input
            type="text"
            value={input}
            onChange={e => {
              setInput(e.nativeEvent.target.value);
            }}
            placeholder="Enter Wallet Address"
          />
          <button
            disabled={!isValid()}
            onClick={() => {
              setAddress(input);
            }}
          >
            <Check />
          </button>
        </div>
      </div>
    </div>
  );
}
