import React, { useRef, useEffect } from "react";
import { BackButton, Loading } from "./";
import QRCode from "qrcode";
import "./QRScan.scss";

export default function QRScan({ address }) {
  const canvas = useRef();
  const shortAddress = address
    ? `${address.slice(0, 6)}...${address.slice(37, 41)}`
    : "";

  useEffect(() => {
    if (canvas.current && address) {
      QRCode.toCanvas(canvas.current, `ethereum:${address}`, error => {
        if (error) {
          console.error(error);
        }
      });
    }
  }, [canvas, address]);

  if (!address) {
    return <Loading />;
  }

  return (
    <div className="qr-scan-receiver">
      <BackButton />
      <canvas id="qr-code" ref={canvas}></canvas>
      <h1>{shortAddress}</h1>
      <h2>Scan to receive your NFT Key to the City</h2>
    </div>
  );
}
